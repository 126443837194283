/**
 * This controller gets a video's url from user input on the Video Embed Link blog builder block
 * It then checks to see if the input is a YouTube or Vimeo link. 
 * Then it uses oEmbed to fetch the video's data and render out the correct markup for the video embed
 * It shouldn't matter what video link is used, we're checking for most cases and adjusting if necessary
 *    ie, https://player.vimeo.com/video/806048205
 *        https://vimeo.com/806048205
 *        https://www.youtube.com/watch?v=x-vtLjr_QXI
 *        https://www.youtube.com/embed/x-vtLjr_QXI
 *        https://youtu.be/x-vtLjr_QXI
 * 
 */

import { Controller } from "stimulus";
 
export default class extends Controller {
    static targets = ["container"];

    initialize() { }

    connect() {
        this.containerTargets.forEach((el) => {
            // get and render thumbnails for each video
            this.handleVideo(el);
        })
    }

    handleVideo(el) {
        const videoUrl = el.getAttribute('data-url');
        const oEmbedData = this.getOembedData(videoUrl, el);
        this.renderThumb(oEmbedData, el);
    }

    getOembedData(videoUrl, el) {
    let source = encodeURI(videoUrl);
    let oEmbedUrl = '';
    const pattern = /^https?:\/\/(www\.)?(youtube\.|youtu\.be)/i;

    if (pattern.test(source)) {
        // if the user copies the embed url, change it to the watch url
        if (source.includes('embed')) {
            source = source.replace('/embed/', '/watch?v=');
        }
        oEmbedUrl = new URL(`https://youtube.com/oembed?autoplay=1&url=${source}&format=json&maxwidth=800&maxheight=500`);
    } else if (source.includes('vimeo')) {
        oEmbedUrl = new URL(`https://vimeo.com/api/oembed.json?autoplay=1&url=${source}&width=9999`);
    }

    // fetch the html data from oembed
    const oembed = fetch(oEmbedUrl)
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
    return oembed;
    }

    renderThumb(data, el) {
        const getEmbed = () => {
            data.then((a) => {
            // build img markup & set embed html as attribute
            let thumb = a.thumbnail_url;
            let html = a.html;
            
            // adjustments for youtube
            if (thumb.includes('ytimg')) {
                thumb = thumb.replace('hqdefault', 'maxresdefault');
            }
            if (html.includes('youtube')) {
                html = html.replace('feature=oembed', 'autoplay=1&rel=0&modestbranding=1');
            }

            // create thumbnail and play button icon
            let img = `<img src="${thumb}" height="${a.thumbnail_height}" width="${a.thumbnail_width}" alt="${a.title} Thumbnail Play Button" class="video-thumb" data-embed="${encodeURIComponent(html)}" style="cursor: pointer;" /><svg class="video-thumb_play" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50" fill="#60768A" fill-opacity="0.4"/><circle cx="50" cy="50" r="40" stroke="white" stroke-width="4"/><path d="M66 50.5L39.75 65.6554L39.75 35.3446L66 50.5Z" fill="white"/></svg>`;

            el.innerHTML = img;
            // Set the aspect ratio on the container just in case it is not 16/9
            el.style.aspectRatio = `${a.width} / ${a.height}`;
            el.setAttribute("data-action", `click->${this.identifier}#swapThumb`)
            });
        };
        getEmbed();
    }

    swapThumb(e) {
        e.preventDefault();
        let el = e.currentTarget;
        let html = el.querySelector('.video-thumb').getAttribute('data-embed');
        el.innerHTML = decodeURIComponent(html);
    }
}