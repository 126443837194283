import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide", "indicator"];

  initialize() {
    this.autoAdvance = true;
    this.pauseOnMouseEnter = true;

    // Create scoped callbacks, using arrow functions, that can be
    // easily referenced later when removing their event listeners.
    this.disableAutoAdvanceHandler = () => this.disableAutoAdvance();
    this.enableAutoAdvanceHandler = () => this.enableAutoAdvance();
    this.slideClickHandler = (e) => this.slideClick(e);
  }

  connect() {
    // Get the current index from a data attribute, if available
    this.index = this.data.has("index") ? Number(this.data.get("index")) : 0;

    // Get the autoadvance delay from a data attribute, if available
    this.advanceIntervalMs = this.data.has("delay")
      ? parseInt(this.data.get("delay"))
      : 5000;

    // Create the anncounce region
    this.createAnnounceRegion();

    // Show the initial current slide
    this.showSlide(this.index);

    // Start the autoadvance, if enabled
    if (this.autoAdvance) {
      this.startAutoAdvanceTimeout();
    }

    // Add an event handler to receeve clicks anywhere on a slide, not just
    // the cta.
    this.slideTargets.forEach((el, i) => {
      el.addEventListener("click", this.slideClickHandler);
    });

    // If keyboard focus enters the carousel, pause the autoadvance
    this.element.addEventListener("focusin", this.disableAutoAdvanceHandler);
    this.element.addEventListener("focusout", this.enableAutoAdvanceHandler);

    // Optionally, if the user mouses into the carousel, pause the autoadvance
    if (this.pauseOnMouseEnter) {
      this.element.addEventListener(
        "mouseenter",
        this.disableAutoAdvanceHandler
      );
      this.element.addEventListener(
        "mouseleave",
        this.enableAutoAdvanceHandler
      );
    }
  }

  disconnect() {
    // Write the current state to the DOM
    this.data.set("index", this.index);

    // Remove listeners
    this.slideTargets.forEach((el, i) => {
      el.removeEventListener("click", this.slideClickHandler);
    });
    this.element.removeEventListener("focusin", this.disableAutoAdvanceHandler);
    this.element.removeEventListener("focusout", this.enableAutoAdvanceHandler);
    if (this.pauseOnMouseEnter) {
      this.element.removeEventListener(
        "mouseenter",
        this.disableAutoAdvanceHandler
      );
      this.element.removeEventListener(
        "mouseleave",
        this.enableAutoAdvanceHandler
      );
    }
  }

  showSlide(index) {
    this.slideTargets.forEach((el, i) => {
      // TODO -
      el.classList.toggle("active", index == el.dataset.slideIndex);
    });
    if (this.indicatorTargets[index]) {
      this.indicatorTargets[index].checked = true;
    }
    this.announceCurrentItem();
    if (this.autoAdvance) {
      this.startAutoAdvanceTimeout();
    }
  }

  selectSlide(e) {
    let el = e.currentTarget;
    this.index = el.getAttribute("value");
    this.showSlide(this.index);

    // This was a deliberate user action, set the focus
    // to the newly revealed slide
    let slide = this.slideTargets[this.index];
    slide.setAttribute("tabindex", "-1");
    // slide.focus();
  }

  nextSlide() {
    this.index = (this.index + 1) % this.slideTargets.length;
    this.showSlide(this.index);
    if (this.autoAdvance) {
      this.resetAutoAdvanceTimeout();
    }
  }

  prevSlide() {
    if (this.index === 0) {
      this.index = this.slideTargets.length - 1;
    } else {
      this.index = (this.index - 1) % this.slideTargets.length;
    }
    this.showSlide(this.index);
    if (this.autoAdvance) {
      this.resetAutoAdvanceTimeout();
    }
  }

  slideClick(e) {
    // Trigger a click on the CTA within this slide
    let slide = e.currentTarget;
    let cta = slide.querySelector('a');
    cta.click();
  }

  startAutoAdvanceTimeout() {
    this.autoAdvance = true;
    this.element.classList.toggle("carousel--playing", this.autoAdvance);
    this.element.classList.toggle("carousel--paused", !this.autoAdvance);
    window.clearTimeout(this.autoAdvanceIntervalRef);
    this.autoAdvanceIntervalRef = window.setTimeout(
      () => this.nextSlide(),
      this.advanceIntervalMs
    );
  }

  resetAutoAdvanceTimeout() {
    window.clearTimeout(this.autoAdvanceIntervalRef);
    this.startAutoAdvanceTimeout();
  }

  stopAutoAdvanceTimeout() {
    this.autoAdvance = false;
    this.element.classList.toggle("carousel--playing", this.autoAdvance);
    this.element.classList.toggle("carousel--paused", !this.autoAdvance);
    window.clearTimeout(this.autoAdvanceIntervalRef);
  }

  enableAutoAdvance() {
    this.startAutoAdvanceTimeout();
  }

  disableAutoAdvance() {
    this.stopAutoAdvanceTimeout();
  }

  toggleAutoAdvance() {
    if (this.autoAdvance) {
      this.stopAutoAdvanceTimeout();
    } else {
      this.startAutoAdvanceTimeout();
    }
  }

  announceCurrentItem() {
    this.announceEl.textContent =
      "Item " + (this.index + 1) + " of " + this.slideTargets.length;
  }

  createAnnounceRegion() {
    /*
    Create an aria live region to "announce" which item is currently being
    shown in the carousel.
    */
    if (!this.announceEl) {
      this.announceEl = document.createElement("div");
      this.announceEl.setAttribute("aria-live", "polite");
      this.announceEl.setAttribute("aria-atomic", "true");
      this.announceEl.setAttribute("class", "visuallyhidden");
    }    
    this.element.appendChild(this.announceEl);
  }
}
