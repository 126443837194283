import { Controller } from "stimulus";
import Cookies from "js-cookie";
import _defaults from "lodash-es/defaults";
import _keyBy from "lodash-es/keyBy";
import _values from "lodash-es/values";
// import _forIn from "lodash-es/forIn";

export default class extends Controller {
  static targets = ["label", "dropdown", "option"];

  initialize() {
    let alternates = this.getAlternates();
    let sites = this.getSites();
    
    this.renderInitialState(alternates, sites);
    this.element.classList.remove('hidden');
  }

  connect() {}

  getAlternates() {
    if (window.hudl && window.hudl.l10n && window.hudl.l10n.alternates) {
      return window.hudl.l10n.alternates;
    }
    return false;
  }

  getSites() {
    if (window.hudl && window.hudl.l10n && window.hudl.l10n.sites) {
      return window.hudl.l10n.sites;
    }
    return false;
  }

  /**
   * Render the initial state of this component, primarily composed of a
   * select dropdown with available options presented to the visitor.
   * 
   * @param Array alternates Array of alternate language versions of this page
   * @param Array sites Array of supported sites
   */
  renderInitialState(alternates, sites) {
    let sitesData = _keyBy(sites, "language");
    let alternatesData = _keyBy(alternates, "language");
    
    // If there are not page alternates for ALL supported languages, we should
    // still present an option, but fall back to redirecting the visitor to the
    // homepage for that language.
    let optionsData = _defaults(alternatesData, sitesData);
    
    _values(optionsData).forEach(alternate => {
      if (alternate.current === true) {
        // Populate the current language
        this.setLanguageLabel(alternate.languageDisplay);
      }
      
      // create the select option
      let option = document.createElement("option");
      option.value = alternate.language;
      option.text = alternate.languageDisplay;
      option.dataset.external = alternate.external ? "true" : "false";
      
      // `data-target` is used by Stimulus to quickly retrieve these elements
      option.dataset.target = "l10n-selector.option";

      // `data-url` and `data-language-display` will be used to update the
      // UI on change
      if (alternate.external) {
        var params = new URLSearchParams({
          "utm_source": "wwwhudlcom",
          "utm_medium": "web",
          "utm_campaign": "languageSelect"
        });
        alternate.url = `${alternate.url}?${params.toString()}`;
      }
      option.dataset.url = alternate.url;
      option.dataset.languageDisplay = alternate.languageDisplay;
      option.setAttribute("translate", "no");
      
      if (alternate.current) {
        // Mark the current locale as `selected`
        option.setAttribute("selected", true);
      }

      // We use native-language labels, so set the current language indicator
      // to translate=no to prevent tools like Google Translate from changing
      // the predefined labels.
      this.labelTarget.setAttribute("translate", "no");

      // Add the option to the select
      this.dropdownTarget.add(option, null);
    });
  }

  /**
   * Change handler for the language select element.
   * @param Event Change event
   */
  change(event) {
    event.preventDefault();
    // Get the necessary info from the event and the selected option element
    let selectedLanguage = event.currentTarget.value;
    let selectedOptionEl = this.dropdownTarget.selectedOptions[0];
    let label = selectedOptionEl.dataset.languageDisplay;
    let url = selectedOptionEl.dataset.url;
    let external = (selectedOptionEl.dataset.external === "true") ? true : false;

    // Update the label display
    this.setLanguageLabel(label);
    // Store the user's preference in a cookie for reference later
    if (!external) {
      this.saveUserPreference(selectedLanguage);
    }
    // Redirect to the language selected by the user
    window.location = url;
  }

  setLanguageLabel(languageDisplay) {
    this.labelTarget.innerHTML = languageDisplay + " ▾";
  }

  checkBrowserPreference() {
    let languages = navigator.languages;
  }

  retrieveUserPreference() {
    return Cookies.get("mktgLanguagePref");
  }

  saveUserPreference(language) {
    if (window.hasOwnProperty("hasConsent") && window.hasConsent("functional")) {
      Cookies.set("mktgLanguagePref", language, { expires: 365 });
    }
  }
}
