import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list","link"];

  initialize() {
    this.canonical = document.querySelector('link[rel=canonical').href;
    this.areTxEventsRegistered = false;

    this.whitelist = [];
    if (this.data.has('whitelist')) {
      this.whitelist = JSON.parse(this.data.get('whitelist'));
    }
  }

  connect() {
    this.registerTxSetupCallbacks();
  }

  disconnect() {
    if (window.Transifex) {
      Transifex.live.unbind(this.txOnFetchLanguages);
      Transifex.live.unbind(this.txOnTranslatePage);
    }
  }

  /**
   * @returns {Boolean}
   */
  isTxLoaded() {
    return (window.Transifex !== undefined);
  }

  registerTxSetupCallbacks() {
    if (this.areTxEventsRegistered) {
      // Callbacks are already registered
      return;
    }

    if (!this.isTxLoaded()) {
      // TransifexLive is not yet loaded, check back soon.
      if (this.txPollTimeoutRef) {
        window.clearTimeout(this.txPollTimeoutRef);
      }
      this.txPollTimeoutRef = window.setTimeout(() => { this.registerTxSetupCallbacks() }, 250);
      return;
    }

    // Tx *is* loaded and the event handlers have not yet been registered.
    // Get to work!
    Transifex.live.onFetchLanguages((languages) => this.txOnFetchLanguages(languages));
    Transifex.live.onTranslatePage((code) => this.txOnTranslatePage(code));
    this.areTxEventsRegistered = true;
  }

  translate(event) {
    let lang = event.currentTarget.getAttribute("hreflang");
    let href = event.currentTarget.href;
    event.preventDefault();
    if (window.Transifex) {
      Transifex.live.translateTo(lang);
    }
    history.replaceState({ lang: lang }, '', href);
  }

  showComponent() {
    this.element.classList.add("txLanguages--initialized");
    this.element.removeAttribute("aria-hidden");
  }

  txOnFetchLanguages(languages) {
    this.populateLanguageList(languages);
  }

  txOnTranslatePage(code) {
    this.setSelectedIndicator(code);
  }

  /**
   * Note that the language list can be updated more than once,
   * so the handler should properly handle cleaning up any
   * existing elements before creating new ones.
   * @param {Array} languages Array of languages provided by Tx
   */
  populateLanguageList(languages) {
    let listEl = this.listTarget;
    let head = document.querySelector("head");

    // empty the list
    while (listEl.firstChild) {
      listEl.removeChild(listEl.firstChild);
    }

    // Filter the language list from Tx using the provided whitelist
    let filteredLanguages = languages.filter(lang => this.whitelist.includes(lang.code));

    // If we've made it this far, AND there is more than one language,
    // then reveal the language picker.
    if (filteredLanguages.length > 1) {
      this.showComponent();
    }

    filteredLanguages.forEach(language => {
      let li = document.createElement("li");
      let alt = document.createElement("link");
      let href = this.canonical;
      href = href + "/" + language.code;

      li.appendChild(this.createLanguageLinkEl(language.name, language.code, href));
      listEl.appendChild(li);

      // Create link=rel alternate tags for the available languages
      // TODO: This functionality should maybe go somewhere else?
      let oldAlt = document.querySelector("link[lang="+language.code+"]");
      if (oldAlt) { oldAlt.remove(); }
      alt.rel = "alternate";
      alt.href = href;
      alt.hreflang = language.code;
      head.appendChild(alt);
    });

    this.setSelectedIndicator(Transifex.live.detectLanguage());
  }

  createLanguageLinkEl(name, code, href) {
    let a = document.createElement("a");
    a.href = href;
    a.classList.add('lang');
    a.innerHTML =
      '<span class="lang__name">' +
      name +
      '</span> <span class="lang__code">(' +
      code.toUpperCase() +
      ")</span>";
    a.setAttribute("hreflang", code);
    a.setAttribute("data-target", "tx-language-picker.link");
    a.setAttribute("data-action", "tx-language-picker#translate");
    return a;
  }

  setSelectedIndicator(code) {
    this.linkTargets.forEach( (el) => {
      el.classList.toggle("selected", (code===el.getAttribute("hreflang")));
    });
  }

}
